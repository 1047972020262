<template>
	<div>
		<div class="modal-header">
			<button
				data-test-id="close-button"
				@click="closeModal"
				aria-label="Close"
				class="close"
				type="button"
			>
				<span aria-hidden="true">×</span>
			</button>
		</div>
		<div class="modal-body">
			<h5 class="text-center mb-4">Do you have any questions in the process?</h5>
			<p class="text-justify">
				Contact us and we will answer any questions you may have during the hiring process. We are
				here to help you.
			</p>
			<div class="d-flex callHelp justify-content-center mt-4">
				<img alt src="@/assets/images/call.svg" />
				<span class="tfLinkEX">{{ telephone }}</span>
			</div>
			<p class="text-center font-weight-bold text-secondary mt-4">WORKING HOURS</p>
			<p class="text-center font-weight-bold text-secondary mt-1">
				9:00 to 18:00, Monday to Friday.
			</p>
		</div>
		<div class="modal-footer justify-content-center"></div>
	</div>
</template>

<script>
export default {
	name: 'm-call-us',

	computed: {
		telephone({ $store }) {
			return $store.state.app.telephone;
		},
	},

	methods: {
		closeModal() {
			this.$store.dispatch('modal/close');
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-header span {
	font-size: 37px;
	color: #c9c9c9;
}
.modal-body {
	margin: 0 30px;
	padding: 0 15px;
}
.modal-body p {
	font-size: 14px;
}
.modal-body .callHelp {
	height: 40px;
}
.modal-body .callHelp img {
	width: 35px;
	height: 36px;
	margin-right: 10px;
}
.modal-body .callHelp span {
	font-size: 24px;
}
</style>
